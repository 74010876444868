//
// blog.scss
//
.blog {
    transition: all 0.3s ease;
    .content {
        h4 {
            line-height: 1.2;
        }
        .title {
            font-size: 20px;
            color: #162638 !important;
            transition: all 0.5s ease;
            &:hover {
                color: $primary !important;
            }
        }
        .post-meta {
            .like, .comments, .readmore {
                font-size: 15px;
                transition: all 0.5s ease;
            }
            .like {
                &:hover {
                    color: $danger !important;
                }
            }
            .comments {
                &:hover {
                    color: $success !important;
                }
            }
            .readmore {
                &:hover {
                    color: $secondary !important;
                }
            }
        }
    }
   
    .author {
        position: absolute;
        top: 5%;
        left: 5%;
        z-index: 1;
        opacity: 0;
        transition: all 0.5s ease;
    }
    &:hover {
        transform: translateY(-10px);
        .overlay {
            opacity: 0.4;
        }
        .author {
            opacity: 1;
        }
    }
}

//Sidebar
.sidebar {
    position: sticky;
    top: 80px;
    .widget {
        .widget-search {
            form {
                position: relative;
            }
        }
        .widget-search, .searchform { 
            input[type="text"] {
                box-shadow: none;
                padding: 12px 15px;
                height: 45px;
                font-size: 14px;
                display: block;
                width: 100%;
                outline: none !important;
                padding-right: 45px;
            }
            input[type="submit"] {
                position: absolute;
                top: 5px;
                right: 10px;
                opacity: 0;
                width: 40px;
                height: 40px;
            }
        }
        .widget-search {
            .searchform:after {
                content: "\F349";
                position: absolute;
                font-family: "Material Design Icons";
                right: 16px;
                top: 15px;
                font-size: 20px;
                line-height: 20px;
                pointer-events: none;
            }
        }
        .widget-title {
            font-size: 18px;
        }
        
        .blog-catagories{
            li {
                padding-bottom: 10px;
                &:last-child {
                    padding-bottom: 0;
                }
                a, span {
                    font-size: 15px;
                }
                a {
                    color: $dark;
                    transition: all 0.5s ease;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }      
        .post-recent {
            padding-bottom: 15px;
            &:last-child {
                padding-bottom: 0;
            }
            .post-recent-thumb {
                width: 25%;
            }
            
            .post-recent-content {
                width: 75%;
                padding-left: 10px;
                a {
                    display: block;
                    color: $dark;
                    font-size: 15px;
                    transition: all 0.5s ease;
                    &:hover {
                        color: $primary;
                    }
                }
                span {
                    font-size: 13px;
                }
            }
        }
        .tagcloud {
            > a {
                background: $gray-200;
                color: $dark;
                display: inline-block;
                font-size: 9px;
                letter-spacing: 1px;
                margin: 5px 10px 5px 0;
                padding: 8px 12px 7px;
                text-transform: uppercase;
                transition: all 0.5s ease;
                font-weight: 600;
                &:hover {
                    background: $primary;
                    color: $white;
                }
            }
        }
    }
}

// single blog
.page-title {
    font-size: 20px;
}

.media-list {
    .comment-desk {
        .media-heading {
            font-size: 16px;
        }
        a {
            &:hover{
                color: $primary !important;
            }
        }
    }
    .sub-comment {
        border-left: 2px dashed $gray-300;
    }
}

.blog .overlay,
.job-box .job-overlay {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0; 
    transition: all 0.5s ease;
}


.job-box:hover .readmore  {
    color: #6193ff !important;
}
.company-name:hover {
    color: #6193ff !important;
}
//Jobs and Careers


/* common */
.ribbon {
  width: 140px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 10px 0;
  background-color: #6193ff;
  color: #fff;
  font: 700 11px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -2px;
  left: -15px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -15px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

.job-box {
    cursor: pointer;
    transition: all 0.5s ease;
    .job-overlay {
        opacity: 0.6;
    }
    .head {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .position {
        position: absolute;
        top: 15px;
        left: 15px;
        a {
            transition: all 0.5s ease;
            &:hover {
                color: $primary !important;
            }
        }
    }
    .firm-logo {
        left: 0;
        right: 0;
        height: 90px;
        width: 90px;
        line-height: 60px;
        margin: 0 auto;
        background-repeat: no-repeat;
        background-size: cover;
        top: -30px;
        border-radius: 5px;
    }
    .song-name {
        max-width: 270px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: auto;
        white-space: nowrap;
    }
    .artist-name{
        max-width: 270px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: auto;
        white-space: nowrap;
    }
    .company-name {
        transition: all 0.5s ease;
        font-weight: 800;
        &:hover {
            color: $primary !important;
        }
    }
    
}

//page-job-candindate.html, page-job-company.html
.cover-bg {
    height: 140px;
}

@media (max-width: 768px) {
    .job-profile {
        margin-top: 1.5rem !important;
    }
     .mt-4-mobile {
        margin-top: 2.5rem !important;
    }
    .mt-2-mobile {
        margin-top: 1.75rem !important;
    }
    .mb-2-mobile {
        margin-bottom: 1.75rem !important;
    }
    .cover-bg {
        height: unset;
    }
    .bottom-sec-profile {
        margin-top: 0px;
    }

    
}
.react-skeleton-load {
    line-height: unset !important;
}
.bottom-sec-profile {
    margin-top: -10px;
}
@media (max-width: 480px) {
    .mt-8-mobile {
        margin-top: 11.75rem !important;
    }
    .mt-10-mobile {
        margin-top: 13.75rem !important;
    }
    .mt-11-mobile {
        margin-top: 14.75rem !important;
    }
    .mt-12-mobile {
        margin-top: 15.75rem !important;
    }
    .mt-14-mobile {
        margin-top: 17.4rem !important;
    }
    .mt-16-mobile {
        margin-top: 18.4rem !important;
    }
    .mt-24-mobile {
        margin-top: 50rem !important;
    }
    .mb-0-mobile {
        margin-bottom: 0px;
    }
}
.h-t-e-number {
    width: 22px;
    height: 22px;
    margin-top: -2px;
    margin-right: 8px;                              
}

@media (min-width: 960px) {
    .detail-modal {
        width: 960px;
        outline: none;
    }
}


@media (max-width: 959px) {
    .detail-modal {
        width: 720px;
        outline: none;
    }
}
@media (max-width: 720px) {
    .detail-modal {
        width: auto;
        outline: none;
    }
}

.d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.img-showcase {
    width: 100%;
    height:auto;
}
.img-previews{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 16px;
    width: 100%;
}
.preview-img {
    height: 50px;
    width: auto;
    
}
.flex-col{
    display: flex;
    flex-direction: column;
}
@media (max-width: 767px) {
    .company-logo {
        float: none !important;
        text-align: left !important;
    }
}