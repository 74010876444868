//
// Bootstrap-custom.scss
//

@each $name,
$value in $colors {
    .bg-#{$name} {
        background-color: #{$value} !important;
    }
    .text-#{$name} {
        color: #{$value} !important;
    }
    a {
        &.text-#{$name} {
            &:hover,
            &:focus {
                color: darken($value, 5%) !important;
            }
        }
    }
}

.btn-outline-secondary-mobile {
    color: #6193ff !important;
    border-color: #6193ff !important;
    box-shadow: 0 2px 3px 0 rgba(94, 135, 255, 0.3);
}

.btn-outline-secondary-mobile:hover{
    color: #fff !important;
    background-color: #6193ff !important;
    border-color: #6193ff !important;
}

@media (max-width: 768px) {
  .btn-block-mobile {
    display: block;
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px;
  }
  .btn-outline-secondary-mobile {
    box-shadow: 0 4px 6px rgba(132, 146, 166, 0.1), 0 1px 3px rgba(132, 146, 166, 0.1) !important;
    background-color: #f7fafc !important;
    color: #8492a6 !important;
    border: 1px solid #dee2e6 !important;
   }
}

.btn-default {
    box-shadow: 0 4px 6px rgba(132, 146, 166, 0.1), 0 1px 3px rgba(132, 146, 166, 0.1) !important;
    background-color: #f7fafc;
    color: #8492a6 !important;
    border: 1px solid #dee2e6 !important;
}

.btn-default:hover {
    box-shadow: 0 4px 6px rgba(132, 146, 166, 0.1), 0 1px 3px rgba(132, 146, 166, 0.1) !important;
    background-color: #dae0e5;
    border: 1px solid #dee2e6 !important;

    
}

.badge-circle {
    border-radius: 200px !important;
    padding: 5px 5px !important;
    font-size: 100%;
}

.cursor-pointer {
    cursor: pointer;
}
.pb-100 {
    padding-bottom: 100px;
}
//li (list inline item)
.list-inline-item:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 5px;
}

// Rounded
.rounded {
    border-radius: 8px !important;
}
.rounded-top {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.rounded-left {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}
.rounded-bottom {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}
.rounded-right {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

//Border

.border-top {
    border-top: 1px solid $gray-200 !important;    
}
.border-bottom {
    border-bottom: 1px solid $gray-200 !important;    
}
.border-left {
    border-left: 1px solid $gray-200 !important;    
}
.border-right {
    border-right: 1px solid $gray-200 !important;    
}

//Small
.small, small {
    font-size: 90%;
}

