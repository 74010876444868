.bg-primary,
.btn-primary,
.btn-outline-primary:hover, 
.btn-outline-primary:focus, 
.btn-outline-primary:active, 
.btn-outline-primary.active, 
.btn-outline-primary.focus, 
.btn-outline-primary:not(:disabled):not(.disabled):active,
.badge-primary,
.pagination .active a,
.nav-pills .nav-link.active,
.custom-control-input:checked ~ .custom-control-label:before,
#preloader #status .spinner .double-bounce1, #preloader #status .spinner .double-bounce2,
.social-icon li a:hover, .profile-icons li a:hover,
.back-to-top:hover,
.back-to-home a,
#topnav .navbar-toggle.open span:hover,
.gradient,
.flex-control-paging li a.flex-active,
.owl-theme .owl-dots .owl-dot span,
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots.clickable .owl-dot:hover span,
.watch-video a .play-icon-circle,
.sidebar .widget .tagcloud > a:hover,
.flatpickr-day.selected, 
.flatpickr-day.selected:hover,
.bg-animation-left.crypto-home:after,
.classic-app-image .bg-app-shape:after,
.app-feature-shape-right:after,
.app-feature-shape-left:after,
.classic-saas-image .bg-saas-shape:after,
.saas-feature-shape-left:after,
.saas-feature-shape-right:after {
    
    background-color:  #162638;
}

@media (min-width: 768px) {
  .container {
    max-width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1240px !important;
  }
}
.text-primary,
.btn-outline-primary,
.badge.badge-outline-primary,
.alert-outline-primary,
.page-next-level .page-next li a:hover,
.faq-content .card a.faq[data-toggle=collapse],
.faq-content .card a.faq[data-toggle=collapse]:before,
#topnav .has-submenu.active .submenu li.active > a,
#topnav .navigation-menu > li:hover > a,
#topnav .navigation-menu > li.active > a,
#topnav .navigation-menu > li > a:hover, 
#topnav .navigation-menu > li > a:active,
#topnav .navigation-menu > li .submenu li a:hover,
#topnav .navigation-menu > li:hover > a,
#topnav .navigation-menu > li.active > a,
#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li .submenu li a:hover,
#topnav .navigation-menu > li.has-submenu.open > a,
#topnav .has-submenu.active a,
.course-feature .title:hover,
.pricing-rates.business-rate:hover .title,
.blog .content .title:hover,
.blog .content .post-meta .readmore:hover,
.sidebar .widget .blog-catagories li a:hover,
.sidebar .widget .post-recent .post-recent-content a:hover,
.media-list .comment-desk a:hover,
.job-box .position a:hover,
.event-schedule .content .title:hover,
.courses-desc .content .title:hover,
.container-filter li a.active, 
.container-filter li a:hover,
.team .name:hover,
.catagories a:hover {
    color:  #162638;
}


.btn-primary,
.btn-outline-primary,
.btn-outline-primary:hover, 
.btn-outline-primary:focus, 
.btn-outline-primary:active, 
.btn-outline-primary.active, 
.btn-outline-primary.focus, 
.btn-outline-primary:not(:disabled):not(.disabled):active,
.badge-outline-primary,
.alert-primary,
.alert-outline-primary,
.pagination .active a,
.form-group .form-control:focus, 
.form-group .form-control.active,
.custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:focus ~ .custom-control-label::before,
.form-control:focus,
.social-icon li a:hover, 
.profile-icons li a:hover,
#topnav .has-submenu.active.active .menu-arrow,
#topnav.scroll .navigation-menu > li:hover > .menu-arrow, 
#topnav.scroll .navigation-menu > li.active > .menu-arrow,
#topnav .navigation-menu > li:hover > .menu-arrow,
#topnav .has-submenu .submenu .has-submenu:hover .submenu-arrow,
.pricing-rates.business-rate:hover,
.pricing-rates.starter-plan,
.event-schedule:hover,
.container-filter li a.active, 
.container-filter li a:hover,
.flatpickr-day.selected, 
.flatpickr-day.selected:hover {
    border-color:  #162638;
}

.btn-primary,
.btn-outline-primary:hover, 
.btn-outline-primary:focus, 
.btn-outline-primary:active, 
.btn-outline-primary.active, 
.btn-outline-primary.focus, 
.btn-outline-primary:not(:disabled):not(.disabled):active {
    box-shadow: 0 2px 3px 0 rgba(47, 85, 212, 0.3);
}

.btn-primary:hover, 
.btn-primary:focus, 
.btn-primary:active, 
.btn-primary.active, 
.btn-primary.focus {
    background-color: #6193ff !important;
    border-color: #6193ff !important;
}

.text-secondary-icon {
    color: #6193ff;
}

.see-how-it-works:hover .text-secondary-icon{
    color: #fff !important;
}

.btn-secondary:hover, 
.btn-secondary:focus, 
.btn-secondary:active, 
.btn-secondary.active, 
.btn-secondary.focus {
    background-color: #3d7afe !important;
} 




.text-secondary-desktop {
    color: #6193ff;
}
@media (max-width: 768px){
    .text-secondary-desktop {
        color: #162638;
    }
}

.btn-secondary {
    /* background: linear-gradient(241.48deg, #80b2ff 34.58%, #6193ff 70.32%); */
    /* background: linear-gradient(241.48deg, #6999ff 54.58%, #4a7ae5 100.32%) !important;   */
    /* background: linear-gradient(220deg, #80b2ff 00%, #6193ff 100%); */
    background: #6193ff;

    /* background: linear-gradient(241.48deg, #80b2ff 20%, #6193ff 85.32%); */
    border: none !important;
    padding: 13px 15px;
}
/* .




.btn-outline-secondary:hover, 
.btn-outline-secondary:focus, 
.btn-outline-secondary:active, 
.btn-outline-secondary.active, 
.btn-outline-secondary.focus {
    background: linear-gradient(241.48deg, #6193ff 40%, #4a7ae5 100.32%) !important;
} */

.btn-outline-secondary:focus {
    color: #6193ff !important;
}

.btn-outline-secondary:hover {
    color: #fff !important;
}


.alert-primary,
.pagination li a:hover,
::selection {
    background-color: #4466d8;
}

.alert-primary .alert-link {
    color: #122258;
}

.pagination li a:hover {
    border-color: #4466d8;
}

a.text-primary:hover, 
a.text-primary:focus {
    color: #6193ff !important;
}

.bg-overlay-gradient {
    background-color: rgba(47, 85, 212, 0.6);
}

.bg-overlay-gradient2 {
    background-image: linear-gradient(90deg, #6193ff, #162638, #6193ff);
}

.gradient {
    background: linear-gradient(45deg, #162638, #162638) !important;
}

.features .image:before {
    background: linear-gradient(45deg, transparent, rgba(47, 85, 212, 0.09));
}

.features:hover .image:before {
    background: rgba(47, 85, 212, 0.07);
}

.key-feature .icon {
    background: linear-gradient(45deg, transparent, rgba(47, 85, 212, 0.15));
}

.owl-theme .owl-dots .owl-dot span {
    background: rgba(47, 85, 212, 0.5) !important;
}

.play-icon i,
.play-icon i:hover {
    -webkit-text-fill-color: #162638;
    
}
  
.play-icon i:hover {
    -webkit-text-fill-color: #6193ff;
    -webkit-text-stroke-color: #6193ff;
}

.event-schedule .date .day {
    background: rgba(47, 85, 212, 0.1);
    box-shadow: 0px 0px 2px 0.25px rgba(47, 85, 212, 0.5);
}

.classic-app-image .bg-app-shape:after,
.app-feature-shape-right:after,
.app-feature-shape-left:after,
.classic-saas-image .bg-saas-shape:after,
.saas-feature-shape-left:after,
.saas-feature-shape-right:after {
    margin-left: 130px;
}

@media (max-width: 600px) {
    .landing-page-top-section {
        padding-top: 0rem !important;
    }
    .classic-app-image .bg-app-shape:after,
.app-feature-shape-right:after,
.app-feature-shape-left:after,
.classic-saas-image .bg-saas-shape:after,
.saas-feature-shape-left:after,
.saas-feature-shape-right:after {
        top: 414px;
        margin-left: 0px;
        width: 415px;
    }
    
}
@media (max-width: 767px){
    .classic-saas-image {
        margin-top: -380px;
    }
}

.nav-pills .nav-link:hover {
    background: rgba(47, 85, 212, 0.06);
}
.step-circle {
    height: 55px;
    width: 55px;
    border-radius: 100%;
    border: 1px solid #6193ff;
    color: #6193ff;
    display: flex;
    align-items: center;
    text-align: center;
}
.step-number{
    margin: auto;
}
