//
// helper.scss
//
.section {
    padding: 100px 0;
    position: relative;
}

.section-90 {
    padding: 90px 0;
    position: relative;
}
.section-two {
    padding: 75px 0;
    position: relative;
}
.bg-overlay {
    background-color: $overlay;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}
.bg-overlay-gradient {
    background-color: $gradident-overlay;
}

.bg-overlay-white {
    background-color: $bg-overlay-white;
}

.bg-overlay-gradient2 {
    background-image: linear-gradient(90deg, $success, $primary, $success);
    opacity: 0.5;
}

//Title
.title-heading {
    .heading {
        font-size: 43px;
        line-height: 1.2;
        font-weight: 800;
        letter-spacing: -.5px;
    }
    .para-desc {
        font-size: 18px;
    }
}
.section-title {
    position: relative;
    .title {
        letter-spacing: -.4px;
        font-weight: 800;
        font-size: 30px;
        color: #162638;
    }
} 
.title {
    letter-spacing: -.5px;
    font-weight: 700;
}

.text-shadow-title {
    text-shadow: 2px 0 0 $white, -2px 0 0 $white, 0 4px 0 rgba($white, 0.4), 0 -2px 0 $white, 1px 1px $white, -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white;
}
.para-desc {
    max-width: 550px;
}
.mt-100 {
    margin-top: 100px;
}

.mb-60 {
    margin-bottom: 60px;
}
//Shapes
.shape {
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 0;
    &>svg {
        transform: scale(2);
        width: 100%;
        height: auto;
        transform-origin: top center;
    }
}

.mover {
    animation: mover 1s infinite alternate;
}
@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(5px);
    }
}

@media (min-width: 768px) {
    .margin-top-100 {
        margin-top: 100px;
        .heading {
            font-size: 40px;
        }
    }
}
@media (max-width: 992px) {
    .title-heading {
        .heading {
            font-size: 32px !important;
            letter-spacing: -.5px;
        }
    }
    .creativeDirection {
        max-width: 300px;
    }
}
@media (min-width: 1200px) {
    .title-heading {
        .heading {
            font-size: 46px !important;
            line-height: 1.2;
        }
    }
}

.active-competition-section {
    padding:80px 0px;
}
.p-4-desktop {
    padding: 27px;
}

@media (max-width: 768px) {
    .p-4-desktop {
        padding: 1.5rem !important;
        max-height: unset !important;
    }
    .section {
        padding: 60px 0;
    }
    .section-90 {
        padding: 60px 0;
    }
    .active-competition-section {
        padding: 50px 0 !important;
    }
    .title-heading {
        .heading {
            font-size: 31px !important;
            line-height: 1.25;
            font-weight: 800;
            letter-spacing: -.5px;
        }
        .para-desc {
            font-size: 16px;
        }
    }
    .section-title {
        .title {
            font-size: 24px;
        }
    }
    .mt-60 {
        margin-top: 60px;
    }   
}

@media (max-width: 345px) {
    .title-heading {
        .heading {
            font-size: 26px !important;
            line-height: 1.25;
            font-weight: 800;
            letter-spacing: -.5px;
        }
       
    }
}

@media (max-width: 767px) {
    .shape {
        bottom: -1px;
    }
}